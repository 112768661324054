import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { TenantLayout } from "../../../components/layout/tenant-layout";
import { Loading } from "../../../components/loading/loading";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { MoreMenu } from "../../../components/more-menu";
import { MyTooltip } from "../../../components/my-tooltip";
import { SideDialog } from "../../../components/side-dialog";
import { ChartTextDef } from "../../../config/text-def";
import { AuthStore } from "../../../stores/auth-store";
import { DashboardBoardDeleteDialog } from "../components/dashboard-board-delete-dialog";
import { DashboardExportJob } from "../components/dashboard-export-job";
import { DashboardNoData } from "../components/dashboard-no-data";
import { BoardTypeTextDef } from "../config/text-def";
import { DashboardBoardQueryResult, dashboardApi } from "../dashboard-api";
import { BoardType } from "../types";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[100],
  },
  "td, th": {
    border: "none",
  },
}));

export const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isTenantOwner } = AuthStore.useContainer();

  const [hoveredBoardId, setHoveredBoardId] = useState<string>();
  const [sideDialogBoard, setSideDialogBoard] = useState<DashboardBoardQueryResult>();
  const [sideDialogOpen, setSideDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<DashboardBoardQueryResult>();

  const onClickBoard = (id: string) => {
    navigate(`/boards/${id}`);
  };

  const onClickInfoIcon = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, board: DashboardBoardQueryResult) => {
    e.stopPropagation();
    setSideDialogBoard(board);
    setSideDialogOpen(true);
  };

  const onClickNew = () => {
    createDashboardBoardMutation.mutate({
      name: t("dashboard.board.name.untitled"),
    });
  };

  const onClickDelete = (board: DashboardBoardQueryResult) => {
    setDeleteTarget(board);
    setDeleteDialogOpen(true);
  };

  const businessYearsQuery = dashboardApi.useBusinessYears();
  const boardsQuery = dashboardApi.useBoards();
  const customBoardsQuery = dashboardApi.useCustomBoards();
  const createDashboardBoardMutation = dashboardApi.useCreateCustomBoard(t, enqueueSnackbar, navigate);

  if (businessYearsQuery.isLoading || boardsQuery.isLoading || customBoardsQuery.isLoading)
    return (
      <TenantLayout activeKey="dashboard">
        <Grid container>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!businessYearsQuery.data) return null;
  if (!boardsQuery.data) return null;
  if (!customBoardsQuery.data) return null;

  const businessYearExists = Boolean(businessYearsQuery.data.length);
  const customBoardsExists = Boolean(customBoardsQuery.data.length);

  const renderBoard = (board: DashboardBoardQueryResult, boardType: BoardType) => (
    <Paper>
      <ListItemButton
        onClick={() => onClickBoard(board.id)}
        onMouseEnter={() => setHoveredBoardId(board.id)}
        onMouseLeave={() => setHoveredBoardId(undefined)}
        sx={{ py: boardType === "custom" ? 0.5 : 1 }}
      >
        <ListItemText
          primary={
            <Box display="flex" alignItems="center">
              <Box
                mr={1}
                title={board.name}
                sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
              >
                {board.name}
              </Box>
              {board.id === hoveredBoardId && (
                <InfoIcon
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    "&:hover": {
                      color: (theme) => theme.palette.grey[700],
                    },
                  }}
                  onClick={(e) => onClickInfoIcon(e, board)}
                />
              )}
            </Box>
          }
        />
        {/* 将来以下のようにアイコンを追加
          <Box display="flex" mr={-2}>
            <IconButton>
              <StarBorderIcon />
            </IconButton>
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          </Box> */}

        {boardType === "custom" && (
          <Box mr={-1.5}>
            <MoreMenu fontSize={21}>
              <MyTooltip unauthorized={!isTenantOwner}>
                <MenuItem
                  disabled={!isTenantOwner}
                  sx={{ color: (theme) => theme.palette.error.main }}
                  onClick={() => onClickDelete(board)}
                >
                  <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                  {t("delete")}
                </MenuItem>
              </MyTooltip>
            </MoreMenu>
          </Box>
        )}
      </ListItemButton>
    </Paper>
  );

  const renderPresetBoardContainer = () => (
    <Grid container spacing={1}>
      <Grid container item>
        <Typography variant="body1" color={(theme) => theme.palette.grey[600]}>
          {t(BoardTypeTextDef.get("preset") as string)}
        </Typography>
      </Grid>
      <Grid container item spacing={1}>
        {boardsQuery.data.map((board) => (
          <Grid
            item
            xs={12}
            sm={sideDialogOpen ? 12 : 8}
            md={sideDialogOpen ? 8 : 4}
            lg={sideDialogOpen ? 4 : 3}
            key={board.id}
          >
            {renderBoard(board, "preset")}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const renderCustomBoardContainer = () => (
    <Grid container spacing={1}>
      <Grid container item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1" color={(theme) => theme.palette.grey[600]}>
              {t(BoardTypeTextDef.get("custom") as string)}
            </Typography>
          </Grid>
          <Grid item>
            <MyTooltip unauthorized={!isTenantOwner}>
              <Button variant="contained" onClick={onClickNew} disabled={!isTenantOwner}>
                {t("dashboard.board.new")}
              </Button>
            </MyTooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        {!customBoardsExists ? (
          <Grid item xs={12}>
            <DashboardNoData
              title={t("dashboard.board.no-data") as string}
              description={t("dashboard.board.shown-here")}
            />
          </Grid>
        ) : (
          customBoardsQuery.data.map((board) => (
            <Grid
              item
              xs={12}
              sm={sideDialogOpen ? 12 : 8}
              md={sideDialogOpen ? 8 : 4}
              lg={sideDialogOpen ? 4 : 3}
              key={board.id}
            >
              {renderBoard(board, "custom")}
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );

  const renderExportHistoryContainer = () => (
    <Grid container spacing={1}>
      <Grid container item>
        <Typography variant="body1" color={(theme) => theme.palette.grey[600]}>
          {t("dashboard.export.history")}
        </Typography>
      </Grid>
      <Grid container item>
        <DashboardExportJob />
      </Grid>
    </Grid>
  );

  const renderSideDialog = () => (
    <SideDialog open={sideDialogOpen} onClose={() => setSideDialogOpen(false)} title={t("dashboard.board.detail")}>
      <Grid px={2} py={1} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Grid>
          <Typography variant="caption">{t("name")}</Typography>
          <Typography>{sideDialogBoard?.name}</Typography>
        </Grid>
        <Grid mt={2} sx={{ display: "flex", flexDirection: "column", overflow: "hidden" }}>
          <Typography variant="caption">{t("indicator")}</Typography>
          <Box mt={0.5} sx={{ overflow: "auto" }}>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {sideDialogBoard?.charts.map((chart) => (
                    <StyledTableRow key={`${sideDialogBoard?.id}-${chart}`}>
                      <TableCell padding="none" sx={{ px: 1, py: 0.5 }}>
                        <Typography variant="caption">{t(ChartTextDef.get(chart) as string)}</Typography>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </SideDialog>
  );

  return (
    <TenantLayout activeKey="dashboard" sideDialog={renderSideDialog()}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h6">{t("dashboard")}</Typography>
        </Grid>
        {!businessYearExists ? (
          <Grid item xs={12}>
            <Alert severity="warning">
              <Trans i18nKey="alert.dashboard.warning" components={{ l: <Link to="/business-years" /> }} />
            </Alert>
          </Grid>
        ) : (
          <Grid container item>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {renderPresetBoardContainer()}
              </Grid>
              <Grid item xs={12}>
                {renderCustomBoardContainer()}
              </Grid>
              <Grid item xs={12}>
                {renderExportHistoryContainer()}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {deleteDialogOpen && deleteTarget && (
        <DashboardBoardDeleteDialog
          target={deleteTarget}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}
      <LoadingModal open={createDashboardBoardMutation.isLoading} />
    </TenantLayout>
  );
};
