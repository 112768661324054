import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unstable_usePrompt, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HEADER_HEIGHT } from "../../../../../components/layout/global-header";
import { TenantLayout } from "../../../../../components/layout/tenant-layout";
import { Loading } from "../../../../../components/loading/loading";
import { LoadingModal } from "../../../../../components/loading/loading-modal";
import { MyTooltip } from "../../../../../components/my-tooltip";
import { PRESET_BOARD_IDS, SEGMENT_CHARTS } from "../../../../../config/const";
import useBeforeUnload from "../../../../../hooks/use-before-unload";
import { AuthStore } from "../../../../../stores/auth-store";
import { Chart, SegmentChart } from "../../../../../types";
import { snackbarUtil } from "../../../../../utils/snackbar";
import { BaseChartProps } from "../../../classes/base-chart";
import { AverageChildcareLeaveTakenDaysByGenderChart } from "../../../classes/column/binary-gender-type/average-childcare-leave-taken-days-by-gender/average-childcare-leave-taken-days-by-gender-chart";
import { BaseBinaryGenderTypeColumnData } from "../../../classes/column/binary-gender-type/base-binary-gender-type-column-chart";
import { DisabledEmploymentShortageHeadcountChart } from "../../../classes/column/disabled-employment-shortage-headcount/disabled-employment-shortage-headcount-chart";
import { AverageServiceYearByGenderChart } from "../../../classes/column/gender-type/average-service-year-by-gender/average-service-year-by-gender-chart";
import { BaseGenderTypeColumnData } from "../../../classes/column/gender-type/base-gender-type-column-chart";
import { EmploymentHeadcountAgeDistributionOfRegularEmployeeByGenderChart } from "../../../classes/column/gender-type/employment-headcount-age-distribution-of-regular-employee-by-gender/employment-headcount-age-distribution-of-regular-employee-by-gender-chart";
import { EmploymentRatioAgeDistributionOfRegularEmployeeByGenderChart } from "../../../classes/column/gender-type/employment-ratio-age-distribution-of-regular-employee-by-gender/employment-ratio-age-distribution-of-regular-employee-by-gender-chart";
import { HeadcountAgeDistributionByGenderChart } from "../../../classes/column/gender-type/headcount-age-distribution-by-gender/headcount-age-distribution-by-gender-chart";
import { RatioAgeDistributionByGenderChart } from "../../../classes/column/gender-type/ratio-age-distribution-by-gender/ratio-age-distribution-by-gender-chart";
import { RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart } from "../../../classes/column/regular-employee-average-annual-hours-over-scheduled-working-hours/regular-employee-average-annual-hours-over-scheduled-working-hours-chart";
import { RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart } from "../../../classes/column/regular-employee-average-monthly-hours-over-scheduled-working-hours/regular-employee-average-monthly-hours-over-scheduled-working-hours-chart";
import { SpanOfControlChart } from "../../../classes/column/span-of-control/span-of-control-chart";
import { AverageAnnualPaidLeaveConsumedDaysAndRateChart } from "../../../classes/column-line/days-and-rate/average-annual-paid-leave-consumed-days-and-rate/average-annual-paid-leave-consumed-days-and-rate-chart";
import { BaseDaysAndRateTypeColumnLineData } from "../../../classes/column-line/days-and-rate/base-days-and-rate-type-column-line-chart";
import { BaseHeadcountAndRateTypeColumnLineData } from "../../../classes/column-line/headcount-and-rate-type/base-headcount-and-rate-type-column-line-chart";
import { ChildcarePurposeShiftedStartTimeWorkHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/childcare-purpose-shifted-start-time-work-headcount-and-rate/childcare-purpose-shifted-start-time-work-headcount-and-rate-chart";
import { ChildcarePurposeShortTimeWorkHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/childcare-purpose-short-time-work-headcount-and-rate/childcare-purpose-short-time-work-headcount-and-rate-chart";
import { ForeignEmployeeHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-employee-headcount-and-rate/foreign-employee-headcount-and-rate-chart";
import { ForeignExecutiveHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-executive-headcount-and-rate/foreign-executive-headcount-and-rate-chart";
import { ForeignNewGraduateEmployedHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/foreign-new-graduate-employed-headcount-and-rate/foreign-new-graduate-employed-headcount-and-rate-chart";
import { MidCareerEmployedExecutiveHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/mid-career-employed-executive-headcount-and-rate/mid-career-employed-executive-headcount-and-rate-chart";
import { MidCareerEmployedHeadcountAndRateChart } from "../../../classes/column-line/headcount-and-rate-type/mid-career-employed-headcount-and-rate/mid-career-employed-headcount-and-rate-chart";
import { BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData } from "../../../classes/column-line/headcount-and-rate-type-grade-type-exact-grade-or-higher-type/base-headcount-and-rate-type-grade-type-exact-grade-or-higher-type-column-line-chart";
import { FemaleHeadcountAndRateOfDivisionManagerChart } from "../../../classes/column-line/headcount-and-rate-type-grade-type-exact-grade-or-higher-type/female-headcount-and-rate-of-division-manager/female-headcount-and-rate-of-division-manager-chart";
import { FemaleHeadcountAndRateOfSectionManagerChart } from "../../../classes/column-line/headcount-and-rate-type-grade-type-exact-grade-or-higher-type/female-headcount-and-rate-of-section-manager/female-headcount-and-rate-of-section-manager-chart";
import { FemaleHeadcountAndRateOfTeamLeaderChart } from "../../../classes/column-line/headcount-and-rate-type-grade-type-exact-grade-or-higher-type/female-headcount-and-rate-of-team-leader/female-headcount-and-rate-of-team-leader-chart";
import { BaseHeadcountAndRatioTypeColumnLineData } from "../../../classes/column-line/headcount-and-ratio-type/base-headcount-and-ratio-type-column-line-chart";
import { MaleChildcareLeaveTakenDayDistributionChart } from "../../../classes/column-line/headcount-and-ratio-type/male-childcare-leave-taken-day-distribution/male-childcare-leave-taken-day-distribution-chart";
import { BaseHeadcountAndSpanOfControlTypeColumnLineData } from "../../../classes/column-line/headcount-and-span-of-control-type/base-headcount-and-span-of-control-type-column-line-chart";
import { ProvinceDirectSpanOfControlChart } from "../../../classes/column-line/headcount-and-span-of-control-type/province-direct-span-of-control/province-direct-span-of-control-chart";
import { BaseEmployeeTypeLineData } from "../../../classes/line/employee-type/base-employee-type-line-chart";
import { GenderPayGapChart } from "../../../classes/line/employee-type/gender-pay-gap/gender-pay-gap-chart";
import { MaleChildcareLeaveRateByEmployeeTypeChart } from "../../../classes/line/employee-type/male-childcare-leave-rate-by-employee-type/male-childcare-leave-rate-by-employee-type-chart";
import { FemaleManagerRatioChart } from "../../../classes/line/female-manager-ratio/female-manager-ratio-chart";
import { GenderAverageServiceYearGapChart } from "../../../classes/line/gender-average-service-year-gap/gender-average-service-year-gap-chart";
import { AnnualEmployedXYearRetentionRateByGenderChart } from "../../../classes/line/gender-type/annual-employed-x-year-retention-rate-by-gender/annual-employed-x-year-retention-rate-by-gender-chart";
import { AverageAgeByGenderChart } from "../../../classes/line/gender-type/average-age-by-gender/average-age-by-gender-chart";
import { AverageAnnualBonusByGenderChart } from "../../../classes/line/gender-type/average-annual-bonus-by-gender/average-annual-bonus-by-gender-chart";
import { AverageAnnualSalaryByGenderChart } from "../../../classes/line/gender-type/average-annual-salary-by-gender/average-annual-salary-by-gender-chart";
import { BaseGenderTypeLineData } from "../../../classes/line/gender-type/base-gender-type-line-chart";
import { RegularFullTimeEmployeeAverageAnnualHoursActualWorkingHoursByGenderChart } from "../../../classes/line/gender-type/regular-full-time-employee-average-annual-hours-actual-working-hours-by-gender/regular-full-time-employee-average-annual-hours-actual-working-hours-by-gender-chart";
import { RegularFullTimeEmployeeAverageMonthlyHoursActualWorkingHoursByGenderChart } from "../../../classes/line/gender-type/regular-full-time-employee-average-monthly-hours-actual-working-hours-by-gender/regular-full-time-employee-average-monthly-hours-actual-working-hours-by-gender-chart";
import { AverageMonthlyHoursOverStatutoryWorkingHoursByGenderChart } from "../../../classes/line/gender-type/average-monthly-hours-over-statutory-working-hours-by-gender/average-monthly-hours-over-statutory-working-hours-by-gender-chart";
import { AverageAnnualHoursOverStatutoryWorkingHoursByGenderChart } from "../../../classes/line/gender-type/average-annual-hours-over-statutory-working-hours-by-gender/average-annual-hours-over-statutory-working-hours-by-gender-chart";
import { AverageAnnualBonusByGradeTypeChart } from "../../../classes/line/grade-type/average-annual-bonus-by-grade-type/average-annual-bonus-by-grade-type-chart";
import { BaseGradeTypeLineData } from "../../../classes/line/grade-type/base-grade-type-line-chart";
import { MaleChildcareLeaveOrChildcarePurposeLeaveRateChart } from "../../../classes/line/male-childcare-leave-or-childcare-purpose-leave-rate/male-childcare-leave-or-childcare-purpose-leave-rate-chart";
import { MaleChildcareLeaveRateChart } from "../../../classes/line/male-childcare-leave-rate/male-childcare-leave-rate-chart";
import { BaseManagementTypeLineData } from "../../../classes/line/management-type/base-management-type-line-chart";
import { SalaryIncreaseRateByManagementTypeChart } from "../../../classes/line/management-type/salary-increase-rate-by-management-type/salary-increase-rate-by-management-type-chart";
import { ManagerRateChart } from "../../../classes/line/manager-rate/manager-rate-chart";
import { BaseSegmentChart } from "../../../classes/segment-chart/base-segment-chart";
import { BaseDisciplineTypeStackedColumnData } from "../../../classes/stacked-column/discipline-type/base-discipline-type-stacked-column-chart";
import { DisciplineCountByDisciplineTypeChart } from "../../../classes/stacked-column/discipline-type/discipline-count-by-discipline-type/discipline-count-by-discipline-type-chart";
import { BaseEmployeeTypeStackedColumnData } from "../../../classes/stacked-column/employee-type/base-employee-type-stacked-column-chart";
import { FullTimeEmployeeHeadcountByEmployeeTypeChart } from "../../../classes/stacked-column/employee-type/full-time-employee-headcount-by-employee-type/full-time-employee-headcount-by-employee-type-chart";
import { BaseGenderTypeStackedColumnData } from "../../../classes/stacked-column/gender-type/base-gender-type-stacked-column-chart";
import { FamilycareLeaveHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/familycare_leave_headcount_by_gender/familycare-leave-headcount-by-gender-chart";
import { ForeignEmployeeHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/foreign-employee-headcount-by-gender/foreign-employee-headcount-by-gender-chart";
import { FullTimeEmployeeHeadcountByGenderChart } from "../../../classes/stacked-column/gender-type/full-time-employee-headcount-by-gender/full-time-employee-headcount-by-gender-chart";
import { BaseNationalityTypeStackedColumnData } from "../../../classes/stacked-column/nationality-type/base-nationality-type-stacked-column-chart";
import { ForeignEmployeeHeadcountByNationalityChart } from "../../../classes/stacked-column/nationality-type/foreign-employee-headcount-by-nationality/foreign-employee-headcount-by-nationality-chart";
import { AnnualEmployedCompositionByGenderChart } from "../../../classes/stacked-column-line/binary-gender-composition-type/annual-employed-composition-by-gender/annual-employed-composition-by-gender-chart";
import { BaseBinaryGenderCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/binary-gender-composition-type/base-binary-gender-composition-type-stacked-column-line-chart";
import { ExecutiveGenderCompositionChart } from "../../../classes/stacked-column-line/binary-gender-composition-type/executive-gender-composition/executive-gender-composition-chart";
import { BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData } from "../../../classes/stacked-column-line/disabled-employment-headcount-and-rate-type/base-disabled-employment-headcount-and-rate-type-stacked-column-line-chart";
import { DisabledEmploymentHeadcountAndRateChart } from "../../../classes/stacked-column-line/disabled-employment-headcount-and-rate-type/disabled-employment-headcount-and-rate/disabled-employment-headcount-and-rate-chart";
import { AnnualEmployedCompositionByEmploymentTypeChart } from "../../../classes/stacked-column-line/employment-composition-type/annual-employed-composition-by-employment-type/annual-employed-composition-by-employment-type-chart";
import { BaseEmploymentCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/employment-composition-type/base-employment-composition-type-stacked-column-line-chart";
import { BaseGenderCompositionTypeStackedColumnLineData } from "../../../classes/stacked-column-line/gender-composition-type/base-gender-composition-type-stacked-column-line-chart";
import { GenderCompositionChart } from "../../../classes/stacked-column-line/gender-composition-type/gender-composition/gender-composition-chart";
import { BaseGenderHeadcountAndRateTypeStackedColumnLineData } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/base-gender-headcount-and-rate-type-stacked-column-line-chart";
import { ChildcareLeaveHeadcountAndRateByGenderChart } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/childcare-leave-headcount-and-rate-by-gender/childcare-leave-headcount-and-rate-by-gender-chart";
import { SeparatedHeadcountAndRateByGenderChart } from "../../../classes/stacked-column-line/gender-headcount-and-rate-type/separated-headcount-and-rate-by-gender/separated-headcount-and-rate-by-gender-chart";
import {
  DashboardChartDisplaySwitch,
  DashboardChartDisplaySwitchModel,
} from "../../../components/dashboard-chart-display-switch";
import { DashboardChartInBoard } from "../../../components/dashboard-chart-in-board";
import { DashboardChartSelectPane } from "../../../components/dashboard-chart-select-pane";
import { DashboardChartSwitchButton } from "../../../components/dashboard-chart-switch-button";
import { DashboardNoData, DashboardNoDataProps } from "../../../components/dashboard-no-data";
import { DashboardSegmentChartInBoard } from "../../../components/dashboard-segment-chart-in-board";
import { BOARD_HEADER_HEIGHT } from "../../../config/const";
import { ChartNotesTextDef, ChartUnitCaptionTextDef } from "../../../config/text-def";
import { DashboardChartGetRequest, dashboardApi } from "../../../dashboard-api";
import { filters } from "../../../functions/filters";
import { useDashboardNameForm } from "../../../hooks/use-dashboard-name-form";
import { DashboardStore } from "../../../stores/dashboard-store";
import { BaseData } from "../../../types";

export const DashboardBoardIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams() as { id: string };
  const [searchParams, setSearchParams] = useSearchParams();
  const { isTenantOwner } = AuthStore.useContainer();
  const {
    industryCompaniesCounts,
    allIndustryCompaniesCounts,
    displaySwitchDisabled,
    selectedChartType,
    formDirty,
    setFormDirty,
  } = DashboardStore.useContainer();
  const [charts, setCharts] = useState<Chart[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loadedCharts, setLoadedCharts] = useState<Chart[]>([]);
  // TODO: フィルタが実装されたら適用ボタンのdisabledを制御する
  const [isAllChartLoaded, setIsAllChartLoaded] = useState<boolean>(false);
  const [filter, setFilter] = useState<DashboardChartGetRequest>();
  const [displaySwitch, setDisplaySwitch] = useState<DashboardChartDisplaySwitchModel>({
    selectedDisplayValues: [],
    showIndustryAverage: false,
    showAllIndustryAverage: false,
    showDataLabel: true,
  });
  const useSettingsQuery = dashboardApi.useSetting();
  const boardQuery = dashboardApi.useBoard(id);
  const useFiltersInBoardQuery = dashboardApi.useFiltersInBoard();
  const updateCustomBoardMutation = dashboardApi.useUpdateCustomBoard(t, enqueueSnackbar, id);

  // ブラウザバックするときに警告を出す
  unstable_usePrompt({
    when: formDirty,
    message: t("confirmation.dialog.message"),
  });

  // リロードやタブを閉じるときに警告を出す
  useBeforeUnload(() => formDirty);

  const { DashboardNameForm, resetForm, getName } = useDashboardNameForm(boardQuery.data?.name);

  const onAddChart = (addedChart: Chart) => {
    if (!charts.includes(addedChart)) {
      setCharts([...charts, addedChart]);
      setFormDirty(true);
    } else {
      enqueueSnackbar(t("snackbar.dashboard.board.chart.already.added"), {
        autoHideDuration: snackbarUtil.AUTO_HIDE_DURATION_SHORT,
      });
    }
  };

  const onCancelEdit = () => {
    if (formDirty && !window.confirm(t("confirmation.dialog.message") as string)) {
      return;
    }
    setIsEdit(false);
    setFormDirty(false);
    resetForm();
    setCharts(boardQuery.data?.charts || []);
  };

  const getSnackbarMessage = () => {
    return (
      <>
        <Typography>{t("snackbar.dashboard.export.submitted")}</Typography>
        <Typography
          component="a"
          href="/"
          ml={2}
          sx={{ color: "common.white", textDecoration: "underline", cursor: "pointer" }}
        >
          {t("snackbar.dashboard.view.export.history")}
        </Typography>
      </>
    );
  };
  const submitExportMutation = dashboardApi.useSubmitExport(t, enqueueSnackbar, getSnackbarMessage());

  useEffect(() => {
    if (searchParams.get("edit") === "true") {
      setIsEdit(true);
      searchParams.delete("edit");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (useFiltersInBoardQuery.data && useSettingsQuery.data) {
      // 事業年度が登録されていない場合、ルートパスの画面では「事業年度を登録してください」と出て画面から遷移はできないが、
      // 直接URLを入力して遷移した場合は事業年度が登録されていない時に限り、ルートパスにリダイレクトする
      if (useFiltersInBoardQuery.data.businessYears.length > 0) {
        setFilter({
          fromBusinessYearStartDate: filters.getFromBusinessYearStartDate(useFiltersInBoardQuery.data.businessYears),
          toBusinessYearStartDate: useFiltersInBoardQuery.data.businessYears[0].startDate,
          companyType: "original",
          companyIds: [],
          industryDivision: useSettingsQuery.data.industryDivision,
        });
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useFiltersInBoardQuery.data, useSettingsQuery.data]);

  useEffect(() => {
    if (boardQuery.data?.charts) {
      setCharts(boardQuery.data.charts);
    }
  }, [boardQuery.data?.charts]);

  useEffect(() => {
    if (charts) {
      setIsAllChartLoaded(loadedCharts.filter((loadedChart) => charts.includes(loadedChart)).length === charts.length);
    }
  }, [charts, loadedCharts]);

  useEffect(() => {
    if (updateCustomBoardMutation.isSuccess) {
      setCharts(charts);
      setFormDirty(false);
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomBoardMutation.isSuccess]);

  const onChartLoaded = useCallback((chart: Chart) => {
    setLoadedCharts((prev) => {
      // 既にロードされている場合は更新しない（これがないと無限ループになる）
      if (prev.includes(chart)) return prev;
      return [...prev, chart];
    });
  }, []);

  if (boardQuery.isLoading || useFiltersInBoardQuery.isLoading || !filter)
    return (
      <TenantLayout activeKey="dashboard">
        <Grid container>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!boardQuery.data || !useFiltersInBoardQuery.data) return null;

  const onSubmitExport = () => {
    if (filter) {
      submitExportMutation.mutate({
        boardId: id,
        charts: boardQuery.data.charts,
        ...filter,
      });
    }
  };

  const onDelete = (chart: Chart) => {
    setCharts(charts.filter((c) => c !== chart));
    setFormDirty(true);
  };

  const onEdit = () => {
    setIsEdit(true);
  };

  const onUpdate = () => {
    updateCustomBoardMutation.mutate({ id, name: getName(), charts });
  };

  const renderChart = (chart: Chart) => {
    const unitCaption = ChartUnitCaptionTextDef.get(chart) && t(ChartUnitCaptionTextDef.get(chart) as string);
    const notes = ChartNotesTextDef.get(chart)?.map((i18nKey) => t(i18nKey));
    const baseChartProps: BaseChartProps = {
      chart,
      unitCaption,
      notes,
    };
    switch (chart) {
      case "gender_pay_gap":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeLineData, GenderPayGapChart>
            chartInstance={GenderPayGapChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "female_manager_ratio":
        return (
          <DashboardChartInBoard<BaseData, FemaleManagerRatioChart>
            chartInstance={FemaleManagerRatioChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "male_childcare_leave_rate":
        return (
          <DashboardChartInBoard<BaseData, MaleChildcareLeaveRateChart>
            chartInstance={MaleChildcareLeaveRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "male_childcare_leave_rate_by_employee_type":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeLineData, MaleChildcareLeaveRateByEmployeeTypeChart>
            chartInstance={MaleChildcareLeaveRateByEmployeeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "male_childcare_leave_or_childcare_purpose_leave_rate":
        return (
          <DashboardChartInBoard<BaseData, MaleChildcareLeaveOrChildcarePurposeLeaveRateChart>
            chartInstance={MaleChildcareLeaveOrChildcarePurposeLeaveRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "gender_composition_of_all_employee":
      case "gender_composition_of_regular_employee":
      case "gender_composition_of_non_regular_employee":
        return (
          <DashboardChartInBoard<BaseGenderCompositionTypeStackedColumnLineData, GenderCompositionChart>
            chartInstance={GenderCompositionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "full_time_employee_headcount_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeStackedColumnData, FullTimeEmployeeHeadcountByGenderChart>
            chartInstance={FullTimeEmployeeHeadcountByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "full_time_employee_headcount_by_employee_type":
        return (
          <DashboardChartInBoard<BaseEmployeeTypeStackedColumnData, FullTimeEmployeeHeadcountByEmployeeTypeChart>
            chartInstance={FullTimeEmployeeHeadcountByEmployeeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "manager_rate":
        return (
          <DashboardChartInBoard<BaseData, ManagerRateChart>
            chartInstance={ManagerRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "span_of_control":
        return (
          <DashboardChartInBoard<BaseData, SpanOfControlChart>
            chartInstance={SpanOfControlChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "province_span_of_control_of_executive":
      case "province_span_of_control_of_executive_officer":
      case "province_span_of_control_of_general_manager":
      case "province_span_of_control_of_division_manager":
      case "province_span_of_control_of_section_manager":
      case "direct_span_of_control_of_executive":
      case "direct_span_of_control_of_executive_officer":
      case "direct_span_of_control_of_general_manager":
      case "direct_span_of_control_of_division_manager":
      case "direct_span_of_control_of_section_manager":
        return (
          <DashboardChartInBoard<BaseHeadcountAndSpanOfControlTypeColumnLineData, ProvinceDirectSpanOfControlChart>
            chartInstance={ProvinceDirectSpanOfControlChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_age_of_all_employee_by_gender":
      case "average_age_of_regular_employee_by_gender":
      case "average_age_of_executive_officer_by_gender":
      case "average_age_of_general_manager_by_gender":
      case "average_age_of_division_manager_by_gender":
      case "average_age_of_section_manager_by_gender":
      case "average_age_of_team_leader_by_gender":
      case "average_age_of_staff_by_gender":
      case "average_age_of_manager_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAgeByGenderChart>
            chartInstance={AverageAgeByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "headcount_age_distribution_of_regular_employee_by_gender":
      case "headcount_age_distribution_of_manager_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, HeadcountAgeDistributionByGenderChart>
            chartInstance={HeadcountAgeDistributionByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "ratio_age_distribution_of_regular_employee_by_gender":
      case "ratio_age_distribution_of_manager_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, RatioAgeDistributionByGenderChart>
            chartInstance={RatioAgeDistributionByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_service_year_of_all_employee_by_gender":
      case "average_service_year_of_regular_employee_by_gender":
      case "average_service_year_of_non_regular_employee_by_gender":
      case "full_time_employee_average_service_year_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, AverageServiceYearByGenderChart>
            chartInstance={AverageServiceYearByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "gender_average_service_year_gap_of_all_employee":
      case "gender_average_service_year_gap_of_regular_employee":
      case "gender_average_service_year_gap_of_non_regular_employee":
      case "full_time_employee_gender_average_service_year_gap":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, GenderAverageServiceYearGapChart>
            chartInstance={GenderAverageServiceYearGapChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "annual_new_graduate_employed_three_year_retention_rate_of_college_graduate_by_gender":
      case "annual_new_graduate_employed_five_year_retention_rate_of_college_graduate_by_gender":
      case "annual_mid_career_employed_three_year_retention_rate_by_gender":
      case "annual_mid_career_employed_five_year_retention_rate_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AnnualEmployedXYearRetentionRateByGenderChart>
            chartInstance={new AnnualEmployedXYearRetentionRateByGenderChart(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "annual_employed_composition_of_all_employee_by_gender":
      case "annual_employed_composition_of_regular_employee_by_gender":
      case "annual_employed_composition_of_non_regular_employee_by_gender":
      case "annual_new_graduate_employed_composition_of_regular_employee_by_gender":
      case "annual_mid_career_employed_composition_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<
            BaseBinaryGenderCompositionTypeStackedColumnLineData,
            AnnualEmployedCompositionByGenderChart
          >
            chartInstance={AnnualEmployedCompositionByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "annual_employed_composition_of_regular_employee_by_employment_type":
        return (
          <DashboardChartInBoard<
            BaseEmploymentCompositionTypeStackedColumnLineData,
            AnnualEmployedCompositionByEmploymentTypeChart
          >
            chartInstance={AnnualEmployedCompositionByEmploymentTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "employment_headcount_age_distribution_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderTypeColumnData,
            EmploymentHeadcountAgeDistributionOfRegularEmployeeByGenderChart
          >
            chartInstance={EmploymentHeadcountAgeDistributionOfRegularEmployeeByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            edit={isEdit}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "employment_ratio_age_distribution_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeColumnData, EmploymentRatioAgeDistributionOfRegularEmployeeByGenderChart>
            chartInstance={EmploymentRatioAgeDistributionOfRegularEmployeeByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            edit={isEdit}
            type={selectedChartType}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "mid_career_employed_headcount_and_rate_of_all_employee":
      case "mid_career_employed_headcount_and_rate_of_manager":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, MidCareerEmployedHeadcountAndRateChart>
            chartInstance={MidCareerEmployedHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "mid_career_employed_executive_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            MidCareerEmployedExecutiveHeadcountAndRateChart
          >
            chartInstance={MidCareerEmployedExecutiveHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "separated_headcount_and_rate_of_regular_employee_by_gender":
      case "personal_reason_separated_headcount_and_rate_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderHeadcountAndRateTypeStackedColumnLineData,
            SeparatedHeadcountAndRateByGenderChart
          >
            chartInstance={SeparatedHeadcountAndRateByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "executive_gender_composition":
        return (
          <DashboardChartInBoard<BaseBinaryGenderCompositionTypeStackedColumnLineData, ExecutiveGenderCompositionChart>
            chartInstance={ExecutiveGenderCompositionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "female_headcount_and_rate_of_division_manager":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData,
            FemaleHeadcountAndRateOfDivisionManagerChart
          >
            chartInstance={FemaleHeadcountAndRateOfDivisionManagerChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "female_headcount_and_rate_of_section_manager":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData,
            FemaleHeadcountAndRateOfSectionManagerChart
          >
            chartInstance={FemaleHeadcountAndRateOfSectionManagerChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "female_headcount_and_rate_of_team_leader":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeGradeTypeExactGradeOrHigherTypeColumnLineData,
            FemaleHeadcountAndRateOfTeamLeaderChart
          >
            chartInstance={FemaleHeadcountAndRateOfTeamLeaderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "childcare_leave_headcount_and_rate_of_all_employee_by_gender":
      case "childcare_leave_headcount_and_rate_of_regular_employee_by_gender":
      case "childcare_leave_headcount_and_rate_of_non_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderHeadcountAndRateTypeStackedColumnLineData,
            ChildcareLeaveHeadcountAndRateByGenderChart
          >
            chartInstance={ChildcareLeaveHeadcountAndRateByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_childcare_leave_taken_days_of_all_employee_by_gender":
      case "average_childcare_leave_taken_days_of_regular_employee_by_gender":
      case "average_childcare_leave_taken_days_of_non_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseBinaryGenderTypeColumnData, AverageChildcareLeaveTakenDaysByGenderChart>
            chartInstance={AverageChildcareLeaveTakenDaysByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "male_childcare_leave_taken_day_distribution":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRatioTypeColumnLineData, MaleChildcareLeaveTakenDayDistributionChart>
            chartInstance={MaleChildcareLeaveTakenDayDistributionChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "foreign_employee_headcount_by_nationality":
        return (
          <DashboardChartInBoard<BaseNationalityTypeStackedColumnData, ForeignEmployeeHeadcountByNationalityChart>
            chartInstance={ForeignEmployeeHeadcountByNationalityChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "foreign_employee_headcount_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeStackedColumnData, ForeignEmployeeHeadcountByGenderChart>
            chartInstance={ForeignEmployeeHeadcountByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "foreign_employee_headcount_and_rate_of_all_employee":
      case "foreign_employee_headcount_and_rate_of_manager":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, ForeignEmployeeHeadcountAndRateChart>
            chartInstance={ForeignEmployeeHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "foreign_new_graduate_employed_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            ForeignNewGraduateEmployedHeadcountAndRateChart
          >
            chartInstance={ForeignNewGraduateEmployedHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "foreign_executive_headcount_and_rate":
        return (
          <DashboardChartInBoard<BaseHeadcountAndRateTypeColumnLineData, ForeignExecutiveHeadcountAndRateChart>
            chartInstance={ForeignExecutiveHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "disabled_employment_headcount_and_rate":
        return (
          <DashboardChartInBoard<
            BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData,
            DisabledEmploymentHeadcountAndRateChart
          >
            chartInstance={DisabledEmploymentHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "disabled_employment_shortage_headcount":
        return (
          <DashboardChartInBoard<BaseData, DisabledEmploymentShortageHeadcountChart>
            chartInstance={DisabledEmploymentShortageHeadcountChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "regular_full_time_employee_average_monthly_hours_actual_working_hours_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderTypeLineData,
            RegularFullTimeEmployeeAverageMonthlyHoursActualWorkingHoursByGenderChart
          >
            chartInstance={RegularFullTimeEmployeeAverageMonthlyHoursActualWorkingHoursByGenderChart.getInstance(
              baseChartProps
            )}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "regular_full_time_employee_average_annual_hours_actual_working_hours_by_gender":
        return (
          <DashboardChartInBoard<
            BaseGenderTypeLineData,
            RegularFullTimeEmployeeAverageAnnualHoursActualWorkingHoursByGenderChart
          >
            chartInstance={RegularFullTimeEmployeeAverageAnnualHoursActualWorkingHoursByGenderChart.getInstance(
              baseChartProps
            )}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_all_employee":
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_manager":
      case "regular_employee_average_monthly_hours_over_scheduled_working_hours_of_non_manager":
        return (
          <DashboardChartInBoard<BaseData, RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart>
            chartInstance={RegularEmployeeAverageMonthlyHoursOverScheduledWorkingHoursChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_all_employee":
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_manager":
      case "regular_employee_average_annual_hours_over_scheduled_working_hours_of_non_manager":
        return (
          <DashboardChartInBoard<BaseData, RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart>
            chartInstance={RegularEmployeeAverageAnnualHoursOverScheduledWorkingHoursChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_monthly_hours_over_statutory_working_hours_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageMonthlyHoursOverStatutoryWorkingHoursByGenderChart>
            chartInstance={AverageMonthlyHoursOverStatutoryWorkingHoursByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_annual_hours_over_statutory_working_hours_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAnnualHoursOverStatutoryWorkingHoursByGenderChart>
            chartInstance={AverageAnnualHoursOverStatutoryWorkingHoursByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_annual_paid_leave_consumed_days_and_rate_of_all_employee":
      case "average_annual_paid_leave_consumed_days_and_rate_of_regular_employee":
      case "average_annual_paid_leave_consumed_days_and_rate_of_non_regular_employee":
        return (
          <DashboardChartInBoard<BaseDaysAndRateTypeColumnLineData, AverageAnnualPaidLeaveConsumedDaysAndRateChart>
            chartInstance={AverageAnnualPaidLeaveConsumedDaysAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "childcare_purpose_short_time_work_headcount_and_rate_of_all_employee":
      case "childcare_purpose_short_time_work_headcount_and_rate_of_male_employee":
      case "childcare_purpose_short_time_work_headcount_and_rate_of_female_employee":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            ChildcarePurposeShortTimeWorkHeadcountAndRateChart
          >
            chartInstance={ChildcarePurposeShortTimeWorkHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_all_employee":
      case "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_male_employee":
      case "childcare_purpose_shifted_start_time_work_headcount_and_rate_of_female_employee":
        return (
          <DashboardChartInBoard<
            BaseHeadcountAndRateTypeColumnLineData,
            ChildcarePurposeShiftedStartTimeWorkHeadcountAndRateChart
          >
            chartInstance={ChildcarePurposeShiftedStartTimeWorkHeadcountAndRateChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "familycare_leave_headcount_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeStackedColumnData, FamilycareLeaveHeadcountByGenderChart>
            chartInstance={FamilycareLeaveHeadcountByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_annual_salary_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAnnualSalaryByGenderChart>
            chartInstance={AverageAnnualSalaryByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_annual_bonus_of_regular_employee_by_gender":
        return (
          <DashboardChartInBoard<BaseGenderTypeLineData, AverageAnnualBonusByGenderChart>
            chartInstance={AverageAnnualBonusByGenderChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "average_annual_bonus_of_regular_employee_by_grade_type":
        return (
          <DashboardChartInBoard<BaseGradeTypeLineData, AverageAnnualBonusByGradeTypeChart>
            chartInstance={AverageAnnualBonusByGradeTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "salary_increase_rate_of_regular_employee_by_management_type":
        return (
          <DashboardChartInBoard<BaseManagementTypeLineData, SalaryIncreaseRateByManagementTypeChart>
            chartInstance={SalaryIncreaseRateByManagementTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      case "discipline_count_by_discipline_type":
        return (
          <DashboardChartInBoard<BaseDisciplineTypeStackedColumnData, DisciplineCountByDisciplineTypeChart>
            chartInstance={DisciplineCountByDisciplineTypeChart.getInstance(baseChartProps)}
            chart={chart}
            type={selectedChartType}
            edit={isEdit}
            filter={filter}
            displaySwitch={displaySwitch}
            onLoaded={onChartLoaded}
            onClickDelete={onDelete}
          />
        );
      default:
        throw new Error(`Invalid chart: ${chart}`);
    }
  };
  const renderSegmentChart = (chart: SegmentChart) => {
    return (
      <DashboardSegmentChartInBoard
        chartInstance={BaseSegmentChart.getInstance({
          chart: chart,
        })}
        chart={chart}
        type={selectedChartType}
        edit={isEdit}
        filter={{
          businessYearStartDate: filter.toBusinessYearStartDate,
          companyType: filter.companyType,
          companyIds: filter.companyIds,
        }}
        displaySwitch={displaySwitch}
        onLoaded={onChartLoaded}
        onClickDelete={onDelete}
      />
    );
  };
  const renderChartGrid = (dashboardNoDataProps: DashboardNoDataProps) => {
    return (
      <>
        {!PRESET_BOARD_IDS.includes(id) && !charts.length ? (
          <Grid item xs={12}>
            <Box pt={4}>
              <DashboardNoData {...dashboardNoDataProps} />
            </Box>
          </Grid>
        ) : (
          <Grid container item spacing={2}>
            {charts.map((chart) => {
              return (
                <Grid item xs={12} md={selectedChartType === "graph" ? 6 : 12} key={chart}>
                  {SEGMENT_CHARTS.includes(chart as SegmentChart)
                    ? renderSegmentChart(chart as SegmentChart)
                    : renderChart(chart)}
                </Grid>
              );
            })}
          </Grid>
        )}
      </>
    );
  };

  return isEdit ? (
    <Grid container height={`calc(var(--vh, 100vh) - ${HEADER_HEIGHT}px)`}>
      <Grid
        container
        item
        component={Paper}
        square
        height={BOARD_HEADER_HEIGHT}
        px={2}
        borderBottom={1}
        borderColor={(theme) => theme.palette.grey[200]}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton onClick={onCancelEdit}>
                  <ArrowBackIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6">{t("dashboard.board.edit-board")}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container item spacing={1}>
              <Grid item>
                <Button variant="contained" color="normal" onClick={onCancelEdit}>
                  {t("cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={!isAllChartLoaded || !formDirty} variant="contained" onClick={onUpdate}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        height={`calc(var(--vh, 100vh) - ${HEADER_HEIGHT}px - ${BOARD_HEADER_HEIGHT}px)`}
        width={"100%"}
        sx={{ overflow: "hidden" }}
      >
        <Grid item xs={9} height={"100%"} sx={{ overflowY: "auto" }}>
          <Grid container pb={2} px={2}>
            <Grid item xs={12}>
              <DashboardNameForm />
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                {renderChartGrid({
                  description: t("dashboard.board.chart.select-right-panel"),
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", height: "100%", overflowY: "auto" }}>
          <DashboardChartSelectPane onClickChart={onAddChart} />
        </Grid>
      </Grid>
      <LoadingModal open={updateCustomBoardMutation.isLoading} />
    </Grid>
  ) : (
    <TenantLayout activeKey="dashboard">
      <Grid container spacing={2}>
        <Grid container item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton onClick={() => navigate("/")}>
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6">{boardQuery.data.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container item alignItems="center">
                {id === "mandatory_three_indicators" && (
                  <Grid item>
                    <MyTooltip unauthorized={!isTenantOwner}>
                      <Button
                        disabled={!isAllChartLoaded || submitExportMutation.isLoading || !isTenantOwner}
                        variant="contained"
                        color="normal"
                        onClick={onSubmitExport}
                      >
                        {/* 特別なスタイル調整（Buttonのサイズが大きくならないようにマイナスを使っている */}
                        <DownloadIcon sx={{ ml: -0.6, mr: 0.25, mb: -0.25 }} />
                        <Typography>{t("dashboard.export.csv")}</Typography>
                      </Button>
                    </MyTooltip>
                  </Grid>
                )}
                {isTenantOwner && !PRESET_BOARD_IDS.includes(id) && selectedChartType === "graph" && (
                  <Grid item>
                    <Button
                      size="small"
                      disabled={!isAllChartLoaded || submitExportMutation.isLoading}
                      variant="contained"
                      color="normal"
                      onClick={onEdit}
                      sx={{ border: "1.5px solid rgba(0, 124, 197, 0)" }}
                    >
                      {t("edit")}
                    </Button>
                  </Grid>
                )}
                <Grid item ml={2}>
                  <DashboardChartSwitchButton disabled={!isAllChartLoaded} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <Grid container rowGap={1}>
              <Grid item xs={12}>
                <DashboardChartDisplaySwitch
                  model={displaySwitch}
                  updateModel={setDisplaySwitch}
                  industryCompaniesCounts={industryCompaniesCounts}
                  allIndustryCompaniesCounts={allIndustryCompaniesCounts}
                  inBoard
                  disabled={!isAllChartLoaded || displaySwitchDisabled}
                />
              </Grid>
              {renderChartGrid({
                title: t("dashboard.board.chart.no-data") as string,
                description: t("dashboard.board.chart.add"),
                onClickEdit: isTenantOwner ? onEdit : undefined,
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LoadingModal open={submitExportMutation.isLoading} />
    </TenantLayout>
  );
};
