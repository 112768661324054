import { Chart } from "../../../types";
import {
  AggregateType,
  BoardType,
  ChartType,
  EmployeeType,
  GenderHeadcountType,
  GenderRatioType,
  BinaryGenderType,
  GenderType,
  Unit,
  DisciplineType,
  BinaryGenderHeadcountType,
  GenderRateType,
  AnnualEmployedCompositionRatioType,
  AnnualEmployedCompositionHeadcountType,
  EmploymentType,
  CalcBasisItem,
  HeadcountAndRateType,
  HeadcountAndRatioType,
  EmployeeGenderType,
  GradeType,
  ManagementType,
  DisabledEmploymentHeadcountAndRateType,
  HeadcountAndSpanOfControlType,
  DisabledEmploymentType,
  DaysAndRateType,
  HeadcountAndRateByGradeType,
} from "../types";

export const BoardTypeTextDef = new Map<BoardType, string>([
  ["preset", "dashboard.board-type.preset"],
  ["custom", "dashboard.board-type.custom"],
]);

export const ChartUnitCaptionTextDef = new Map<Chart, string>([
  ["average_annual_salary_of_regular_employee_by_gender", "dashboard.chart.unit-caption.thousand-yen"],
  ["average_annual_bonus_of_regular_employee_by_gender", "dashboard.chart.unit-caption.thousand-yen"],
  ["average_annual_bonus_of_regular_employee_by_grade_type", "dashboard.chart.unit-caption.thousand-yen"],
]);

export const ChartNotesTextDef = new Map<Chart, string[]>([
  [
    "disabled_employment_headcount_and_rate",
    ["dashboard.chart.notes.disabled-employment-headcount-and-rate-new-employed"],
  ],
]);

export const CalcBasisUnitCaptionTextDef = new Map<Chart, string>([
  // 必要になったら足す
]);

export const CalcBasisNotesTextDef = new Map<Chart, string[]>([
  ["gender_pay_gap", ["dashboard.calc-basis.notes.gender-pay-gap"]],
]);

export const ChartUnitTextDef = new Map<Unit, string>([
  ["percent", "percent"],
  ["headcount", "headcount"],
  ["thousand_yen", "thousand-yen"],
  ["age", "age"],
  ["hours", "hours"],
  ["days", "days"],
  ["years", "years"],
  ["count", "count"],
]);

export const ChartTypeTextDef = new Map<ChartType, string>([
  ["graph", "dashboard.chart-type.graph"],
  ["table", "dashboard.chart-type.table"],
]);

export const AggregateTypeTextDef = new Map<AggregateType, string>([
  ["our_company", "dashboard.aggregate-type.our-company"],
  ["industry_average", "dashboard.aggregate-type.industry-average"],
  ["all_industry_average", "dashboard.aggregate-type.all-industry-average"],
]);

export const CalcBasisItemTextDef = new Map<CalcBasisItem, string>([
  ["average_annual_payment", "dashboard.calc-basis.average-annual-payment"],
  ["total_payment", "dashboard.calc-basis.total-payment"],
  ["employee_count", "dashboard.calc-basis.employee-count"],
  ["female_manager_headcount", "dashboard.calc-basis.female-manager-headcount"],
  ["manager_headcount", "dashboard.calc-basis.manager-headcount"],
  ["male_employee_taking_childcare_leave_count", "dashboard.calc-basis.male-employee-taking-childcare-leave-count"],
  [
    "male_all_employee_with_spouse_gave_birth_count",
    "dashboard.calc-basis.male-all-employee-with-spouse-gave-birth-count",
  ],
  [
    "male_regular_employee_with_spouse_gave_birth_count",
    "dashboard.calc-basis.male-regular-employee-with-spouse-gave-birth-count",
  ],
  [
    "male_non_regular_employee_with_spouse_gave_birth_count",
    "dashboard.calc-basis.male-non-regular-employee-with-spouse-gave-birth-count",
  ],
  ["female_all_employee_gave_birth_count", "dashboard.calc-basis.female-all-employee-gave-birth-count"],
  ["female_regular_employee_gave_birth_count", "dashboard.calc-basis.female-regular-employee-gave-birth-count"],
  ["female_non_regular_employee_gave_birth_count", "dashboard.calc-basis.female-non-regular-employee-gave-birth-count"],
  [
    "all_employee_with_childbirth_or_spouse_birth_count",
    "dashboard.calc-basis.all-employee-with-child-birth-or-spouse-birth-count",
  ],
  [
    "regular_employee_with_childbirth_or_spouse_birth_count",
    "dashboard.calc-basis.regular-employee-with-child-birth-or-spouse-birth-count",
  ],
  [
    "non_regular_employee_with_childbirth_or_spouse_birth_count",
    "dashboard.calc-basis.non-regular-employee-with-child-birth-or-spouse-birth-count",
  ],
]);

export const SegmentChartLegendTextDef = new Map<string, string>([
  ["male_headcount", "dashboard.segment-chart.legend.male-headcount"],
  ["female_headcount", "dashboard.segment-chart.legend.female-headcount"],
  ["male_ratio", "dashboard.segment-chart.legend.male-ratio"],
  ["female_ratio", "dashboard.segment-chart.legend.female-ratio"],
]);

export const ParentRowTextDef = new Map<AggregateType | CalcBasisItem, string>([
  ...Array.from(AggregateTypeTextDef.entries()),
  ...Array.from(CalcBasisItemTextDef.entries()),
]);

export const EmployeeTypeTextDef = new Map<EmployeeType, string>([
  ["all", "dashboard.employee-type.all"],
  ["regular", "dashboard.employee-type.regular"],
  ["non_regular", "dashboard.employee-type.non-regular"],
]);

export const EmploymentTypeTextDef = new Map<EmploymentType, string>([
  ["all", "dashboard.employment-type.all"],
  ["new_graduate", "dashboard.employment-type.new-graduate"],
  ["mid_career", "dashboard.employment-type.mid-career"],
  ["other", "dashboard.employment-type.other"],
]);

export const EmploymentHeadcountTypeTextDef = new Map<AnnualEmployedCompositionHeadcountType, string>([
  ["all_headcount", "dashboard.employment-headcount-type.all"],
  ["new_graduate_headcount", "dashboard.employment-headcount-type.new-graduate"],
  ["mid_career_headcount", "dashboard.employment-headcount-type.mid-career"],
  ["other_headcount", "dashboard.employment-headcount-type.other"],
]);

export const EmploymentRatioTypeTextDef = new Map<AnnualEmployedCompositionRatioType, string>([
  ["new_graduate_ratio", "dashboard.employment-ratio-type.new-graduate"],
  ["mid_career_ratio", "dashboard.employment-ratio-type.mid-career"],
  ["other_ratio", "dashboard.employment-ratio-type.other"],
]);

export const BinaryGenderTypeTextDef = new Map<BinaryGenderType, string>([
  ["male", "dashboard.gender-type.male"],
  ["female", "dashboard.gender-type.female"],
]);

export const GenderTypeTextDef = new Map<GenderType, string>([
  ["all", "dashboard.gender-type.all"],
  ...Array.from(BinaryGenderTypeTextDef.entries()),
]);

export const BinaryGenderHeadcountTypeTextDef = new Map<BinaryGenderHeadcountType, string>([
  ["male_headcount", "dashboard.gender-headcount-type.male"],
  ["female_headcount", "dashboard.gender-headcount-type.female"],
]);

export const GenderHeadcountTypeTextDef = new Map<GenderHeadcountType, string>([
  ["all_headcount", "dashboard.gender-headcount-type.all"],
  ...Array.from(BinaryGenderHeadcountTypeTextDef.entries()),
]);

export const GenderRatioTypeTextDef = new Map<GenderRatioType, string>([
  ["male_ratio", "dashboard.gender-ratio-type.male"],
  ["female_ratio", "dashboard.gender-ratio-type.female"],
]);

export const GenderRateTypeTextDef = new Map<GenderRateType, string>([
  ["all_rate", "dashboard.gender-rate-type.all"],
  ["male_rate", "dashboard.gender-rate-type.male"],
  ["female_rate", "dashboard.gender-rate-type.female"],
]);

export const HeadcountAndRateTypeTextDef = new Map<HeadcountAndRateType, string>([
  ["headcount", "dashboard.headcount-and-rate-type.headcount"],
  ["rate", "dashboard.headcount-and-rate-type.rate"],
]);

export const HeadcountAndRateByGradeTypeTextDef = new Map<HeadcountAndRateByGradeType, string>([
  ["division_manager_exact_headcount", "dashboard.headcount-and-rate-by-grade-type.division-manager-exact-headcount"],
  ["division_manager_exact_rate", "dashboard.headcount-and-rate-by-grade-type.division-manager-exact-rate"],
  ["division_manager_higher_rate", "dashboard.headcount-and-rate-by-grade-type.division-manager-higher-rate"],
  ["section_manager_exact_headcount", "dashboard.headcount-and-rate-by-grade-type.section-manager-exact-headcount"],
  ["section_manager_exact_rate", "dashboard.headcount-and-rate-by-grade-type.section-manager-exact-rate"],
  ["section_manager_higher_rate", "dashboard.headcount-and-rate-by-grade-type.section-manager-higher-rate"],
  ["team_leader_exact_headcount", "dashboard.headcount-and-rate-by-grade-type.team-leader-exact-headcount"],
  ["team_leader_exact_rate", "dashboard.headcount-and-rate-by-grade-type.team-leader-exact-rate"],
  ["team_leader_higher_rate", "dashboard.headcount-and-rate-by-grade-type.team-leader-higher-rate"],
]);

export const HeadcountAndRatioTypeTextDef = new Map<HeadcountAndRatioType, string>([
  ["headcount", "dashboard.headcount-and-ratio-type.headcount"],
  ["ratio", "dashboard.headcount-and-ratio-type.ratio"],
]);

export const HeadcountAndSpanOfControlTypeTextDef = new Map<HeadcountAndSpanOfControlType, string>([
  ["headcount", "dashboard.headcount-and-span-of-control-type.headcount"],
  ["span_of_control", "dashboard.headcount-and-span-of-control-type.span-of-control"],
]);

export const DaysAndRateTypeTextDef = new Map<DaysAndRateType, string>([
  ["days", "dashboard.days-and-rate-type.days"],
  ["rate", "dashboard.days-and-rate-type.rate"],
]);

export const GenderEmployeeTypeTextDef = new Map<EmployeeGenderType, string>([
  ["all_male", "dashboard.calc-basis.employee-gender-type.all-male"],
  ["all_female", "dashboard.calc-basis.employee-gender-type.all-female"],
  ["regular_male", "dashboard.calc-basis.employee-gender-type.regular-male"],
  ["regular_female", "dashboard.calc-basis.employee-gender-type.regular-female"],
  ["non_regular_male", "dashboard.calc-basis.employee-gender-type.non-regular-male"],
  ["non_regular_female", "dashboard.calc-basis.employee-gender-type.non-regular-female"],
]);

export const GradeTypeTextDef = new Map<GradeType, string>([
  ["executive_officer", "dashboard.grade-type.executive-officer"],
  ["general_manager", "dashboard.grade-type.general-manager"],
  ["division_manager", "dashboard.grade-type.division-manager"],
  ["section_manager", "dashboard.grade-type.section-manager"],
  ["team_leader", "dashboard.grade-type.team-leader"],
  ["staff", "dashboard.grade-type.staff"],
]);

export const ManagementTypeTextDef = new Map<ManagementType, string>([
  ["manager", "dashboard.management-type.manager"],
  ["non_manager", "dashboard.management-type.non-manager"],
  ["all", "dashboard.management-type.all"],
]);

export const DisabledEmploymentTypeTextDef = new Map<DisabledEmploymentType, string>([
  ["physical", "dashboard.disabled-employment-type.physical"],
  ["intellectual", "dashboard.disabled-employment-type.intellectual"],
  ["mental", "dashboard.disabled-employment-type.mental"],
]);

export const DisabledEmploymentHeadcountAndRateTypeTextDef = new Map<DisabledEmploymentHeadcountAndRateType, string>([
  [
    "severe_physical_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-physical-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_severe_physical_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-physical-disabled-employee-working-over-30h-headcount",
  ],
  [
    "non_severe_physical_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.non-severe-physical-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_non_severe_physical_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-non-severe-physical-disabled-employee-working-over-30h-headcount",
  ],
  [
    "severe_physical_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-physical-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_severe_physical_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-physical-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "severe_physical_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-physical-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "new_employed_severe_physical_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-physical-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "non_severe_physical_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.non-severe-physical-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_non_severe_physical_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-non-severe-physical-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "physical_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.physical-disabled-employee-headcount",
  ],
  [
    "new_employed_physical_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-physical-disabled-employee-headcount",
  ],

  [
    "severe_intellectual_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-intellectual-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_severe_intellectual_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-intellectual-disabled-employee-working-over-30h-headcount",
  ],
  [
    "non_severe_intellectual_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.non-severe-intellectual-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_non_severe_intellectual_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-non-severe-intellectual-disabled-employee-working-over-30h-headcount",
  ],

  [
    "severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-intellectual-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-intellectual-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "severe_intellectual_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.severe-intellectual-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "new_employed_severe_intellectual_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-severe-intellectual-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "non_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.non-severe-intellectual-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_non_severe_intellectual_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-non-severe-intellectual-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "intellectual_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.intellectual-disabled-employee-headcount",
  ],
  [
    "new_employed_intellectual_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-intellectual-disabled-employee-headcount",
  ],
  [
    "mental_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.mental-disabled-employee-working-over-30h-headcount",
  ],
  [
    "new_employed_mental_disabled_employee_working_over_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-mental-disabled-employee-working-over-30h-headcount",
  ],
  [
    "mental_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.mental-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "new_employed_mental_disabled_employee_working_20h_to_30h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-mental-disabled-employee-working-20h-to-30h-headcount",
  ],
  [
    "mental_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.mental-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "new_employed_mental_disabled_employee_working_10h_to_20h_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-mental-disabled-employee-working-10h-to-20h-headcount",
  ],
  [
    "mental_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.mental-disabled-employee-headcount",
  ],
  [
    "new_employed_mental_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-mental-disabled-employee-headcount",
  ],
  ["disabled_employee_headcount", "dashboard.disabled-employment-headcount-and-rate-type.disabled-employee-headcount"],
  [
    "new_employed_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.new-employed-disabled-employee-headcount",
  ],
  [
    "visual_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.visual-disabled-employee-headcount",
  ],
  [
    "auditory_balance_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.auditory-balance-disabled-employee-headcount",
  ],
  [
    "speech_language_mastication_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.speech-language-mastication-disabled-employee-headcount",
  ],
  [
    "physical_impaired_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.physical-impaired-employee-headcount",
  ],
  [
    "internal_disabled_employee_headcount",
    "dashboard.disabled-employment-headcount-and-rate-type.internal-disabled-employee-headcount",
  ],
  ["disabled_employment_rate", "dashboard.disabled-employment-headcount-and-rate-type.disabled-employment-rate"],
]);

export const DisciplineTypeTextDef = new Map<DisciplineType, string>([
  ["all", "dashboard.discipline-type.all"],
  ["warning", "dashboard.discipline-type.warning"],
  ["reprimand", "dashboard.discipline-type.reprimand"],
  ["pay_cut", "dashboard.discipline-type.pay-cut"],
  ["suspension_from_work", "dashboard.discipline-type.suspension-from-work"],
  ["demotion", "dashboard.discipline-type.demotion"],
  ["recommended_retirement", "dashboard.discipline-type.recommended-retirement"],
  ["disciplinary_dismissal", "dashboard.discipline-type.disciplinary-dismissal"],
]);
